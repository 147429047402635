import React from 'react';
import { Mail } from 'styled-icons/octicons/Mail'
import Language from './language'
import { useIntl, Link } from "gatsby-plugin-intl"

const Menu = () => {
  const intl = useIntl()

  return (
      <nav>
        <ul className='navbar'>
          <li><Link activeClassName="active" to="/">{intl.formatMessage({ id: "MENU.PROFILE" })}</Link></li>
          <li><Link activeClassName="active" to="/skills">{intl.formatMessage({ id: "MENU.SKILLS" })}</Link></li>
          <li><Link activeClassName="active" to="/portfolio">{intl.formatMessage({ id: "MENU.PORTFOLIO" })}</Link></li>
          <li className="link-contact"><Link activeClassName="active" to="/contact"><Mail size="32" style={{ color: "#348AFF" }} /> </Link></li>
          <li>
            <Language />
          </li>
        </ul> 
      </nav>
  )
}

export default Menu;