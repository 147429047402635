/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Menu from './menu'

import MenuContext from './menuContext'
import "../themes/default/index.scss"

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);

  const contextValue = {
    open,
    openMenu: setOpen
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MenuContext.Provider value={contextValue}>
      <div className="wrapper">
        <div className="wrapper-card">
          <div className="content-area">
            <div className="content-background">
              <Header siteTitle={data.site.siteMetadata.title} />
              <div className="content">
                <main>{children}</main>
              </div>
            </div>

          </div> 
        </div>
        <div className={open ? ["menu-responsive active"] : ["menu-responsive desactive"]} >
          <Menu />
        </div>
      </div>
    </MenuContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
