import React, { useState } from 'react'
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { ArrowDropDown} from 'styled-icons/material/ArrowDropDown'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Language = () => {
    const data = useStaticQuery(graphql`
        query {
            fr: file(relativePath: { eq: "fr.png" }) {
                childImageSharp {
                    fluid(maxHeight: 50) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            en: file(relativePath: { eq: "uk.png" }) {
                childImageSharp {
                    fluid(maxHeight: 50) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

  const [rotationFlag, setRotationFlag] = useState(0);
  const [flagOpen, setFlagOpen] = useState(false);
  const [scalation, setScalation] = useState(1);
  const [opacityFlag, setOpacityFlag] = useState(0);

  const changeFlagMenu = () => {
    setRotationFlag(rotationFlag + 180)
    setFlagOpen(!flagOpen)
    if(flagOpen) {
      setScalation(scalation + 0.5)
      setOpacityFlag(0)
    } else {
      setScalation(scalation - 0.5)
      setOpacityFlag(1)
    }
  }

    return (
        <div style={{position:"relative"}}>
                <div style={{position:"relative", display: "flex", alignItems: "center"}}>
                    <div style={{ width: "25px"}} >
                    <IntlContextConsumer>
                        {({ language: currentLocale }) =>
                        currentLocale === 'fr' ?
                        <Img fluid={data.fr.childImageSharp.fluid} imgStyle={{objectFit: 'contain', borderRadius: "2px"}}/> :
                        <Img fluid={data.en.childImageSharp.fluid} imgStyle={{objectFit: 'contain', borderRadius: "2px"}}/>
                        }
                    </IntlContextConsumer>
                    </div>
                    <button style={{padding:"0", background:"transparent", border:"none", transition: "0.5s ease", transform: "rotateX("+rotationFlag+"deg)"}} onClick={changeFlagMenu}><ArrowDropDown size={30}/></button>
                </div>
                <div style={{position: "absolute", display: "flex", flexDirection: "column"}}>
        
                    <button style={{ cursor: "pointer", border: "none", background: "transparent", width: "60px", transition:"0.25s cubic-bezier(0.64, 0.57, 0.67, 1.53)", transform: "scale("+scalation+")", opacity:opacityFlag}} onClick={() => changeLocale('fr')}>
                        <Img fluid={data.fr.childImageSharp.fluid} imgStyle={{objectFit: 'contain', borderRadius: "2px"}}/>
                    </button>
                    <button style={{ cursor: "pointer", border: "none", background: "transparent", width: "60px", transition:"0.75s cubic-bezier(0.64, 0.57, 0.67, 1.53)", transform: "scale("+scalation+")", opacity:opacityFlag}} onClick={() => changeLocale('en')}>
                        <Img fluid={data.en.childImageSharp.fluid} imgStyle={{objectFit: 'contain', borderRadius: "2px"}}/>
                    </button>
                </div>
        </div>
    )
}

export default Language;