// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Burger from './burger';
import Menu from './menu';
import MenuContext from './menuContext'
import { Link } from "gatsby-plugin-intl"

const Header = () => {
  const contextValue = useContext(MenuContext)

  const data = useStaticQuery(graphql`
    query {
      porfileImage: file(relativePath: { eq: "profile.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <header>
        <div style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <div style={{
              width: "80px",
              height: "80px"
            }}>
              <Img fluid={data.porfileImage.childImageSharp.fluid} style={{ borderRadius: "50%" }}/>
            </div>
          </Link>
        </div>
        <Burger open={contextValue.open} setOpen={contextValue.openMenu} />
        <div className="menu-header">
          <Menu />
        </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
